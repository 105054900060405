import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

import { kebabCase, mapEdgesToNodes } from "../helpers";

import { Container, SEO, GraphQLError } from "../components/elements";

import Layout from "../containers";

import { ResponsiveTitle1, ResponsiveTitle3 } from "../components/styled";
import useFirebaseAnalytics from "../hooks/useFirebaseAnalytics";

export const query = graphql`
  query AuthorsPageQuery {
    authors: allSanityAuthor(sort: { fields: [name], order: ASC }) {
      edges {
        node {
          name
          slug {
            current
          }
        }
      }
    }
  }
`;

const AuthorsPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLError errors={errors} />
      </Layout>
    );
  }

  const authorNodes = data && data.authors && mapEdgesToNodes(data.authors);
  const groupedAuthors = authorNodes.reduce((r, e) => {
    // get first letter of name of current element
    const group = e.name[0];
    // if there is no property in accumulator with this letter create it
    if (!r[group]) {
      r[group] = { group, children: [e] };
    }
    // if there is push current element to children array for that letter
    else r[group].children.push(e);
    // return accumulator
    return r;
  }, {});

  const title = "Authors";
  useFirebaseAnalytics(`PAGE: ${title}`);
  return (
    <Layout>
      <SEO title={title} />
      <Container>
        <ResponsiveTitle1>{title}</ResponsiveTitle1>
        <Wrapper>
          <StyledList>
            {Object.values(groupedAuthors).map((grouped) => (
              <li key={grouped.group}>
                <ResponsiveTitle3>{grouped.group}</ResponsiveTitle3>
                <StyledList>
                  {grouped.children.map((author) => (
                    <ListItem key={author.slug.current}>
                      <Link to={`/authors/${kebabCase(author.name)}`}>
                        {author.name}
                      </Link>
                    </ListItem>
                  ))}
                </StyledList>
              </li>
            ))}
          </StyledList>
        </Wrapper>
      </Container>
    </Layout>
  );
};

const Wrapper = styled.div`
  margin-bottom: 5em;
`;

const StyledList = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  margin: 0.25rem;
  a {
    padding: 0.5rem 0.5rem;

    text-decoration: none;
    color: ${(props) => props.theme.accentColor};

    @media (hover: hover) {
      &:hover {
        color: inherit;
      }
    }
  }
`;

export default AuthorsPage;
